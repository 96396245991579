// ** Packages **
import React, { Suspense } from "react";
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

// ** Custom Components **
import RequiresAuth from "modules/Auth/components/RequiresAuth";
import ErrorBoundary from "modules/Auth/pages/ErrorBoundary";
import SettingLayout from "modules/Setting/components/SettingLayout";
import AllSettingRoutes from "modules/Setting/routes";
import PageLoader from "./components/Loaders/PageLoader";
import StaticDashboard from "./components/StaticDesign/StaticDashboard";
import AuthenticationRoutes from "./modules/Auth/routes";

// ** Constants **
import {
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from "./constants/navigation.constant";

// ** Type **
import { RouteObjType } from "types";

// ** Wrapper Components **
const RequiresUnAuth = React.lazy(
  () => import("../src/modules/Auth/components/RequiresUnAuth")
);

// ** Dashboard **
const Dashboard = React.lazy(() => import("modules/Dashboard"));

// ** LEAD MODULE **
const Lead = React.lazy(() => import("modules/Lead"));
const AddEditLead = React.lazy(
  () => import("modules/Lead/components/AddEditLead")
);

// ** Customer Module **
const Customer = React.lazy(() => import("modules/Customer"));
const AddEditCustomer = React.lazy(
  () => import("modules/Customer/components/AddEditCustomer")
);

// ** Order Module **
const Order = React.lazy(() => import("modules/Order"));
const InstallerHistory = React.lazy(() => import("modules/InstallerHistory"));
const AddEditOrder = React.lazy(
  () => import("modules/Order/components/AddEditOrder")
);

// ** Order Module **
const PurchaseOrder = React.lazy(() => import("modules/PurchaseOrder"));
const AddEditPurchaseOrder = React.lazy(
  () => import("modules/PurchaseOrder/components/AddEditPurchaseOrder")
);

// ** Product Module **
const Product = React.lazy(() => import("modules/Product"));
const AddEditProduct = React.lazy(
  () => import("modules/Product/components/AddEditProduct")
);

// ** Sales Commission Module **
const SalesCommission = React.lazy(() => import("modules/SalesCommission"));

// ** Invoice Module **
const Invoice = React.lazy(() => import("modules/Invoice"));
const AddEditInvoice = React.lazy(
  () => import("modules/Invoice/components/AddEditInvoice")
);

// ** Team Module **
const Team = React.lazy(() => import("modules/Team"));
const AddEditTeam = React.lazy(
  () => import("modules/Team/components/AddEditTeam")
);

// ** Report Module **
const Report = React.lazy(() => import("modules/Report"));

// ** Calendar Module **
const Calendar = React.lazy(() => import("modules/Calendar"));

// ** Public Page **
const NotFound = React.lazy(() => import("modules/Auth/pages/NotFound"));
const NotAuthorized = React.lazy(
  () => import("../src/modules/Auth/pages/NotAuthorized")
);

const applySuspense = (routes: RouteObjType[]): RouteObjType[] => {
  return routes.map((route) => ({
    ...route,
    element: (
      <Suspense
        fallback={
          <div className="w-full flex items-center justify-center !h-[100%]">
            <PageLoader />
          </div>
        }
      >
        {route.element}
      </Suspense>
    ),
  }));
};

export const applyRequiresAuth = (routes: RouteObjType[]): RouteObjType[] => {
  return routes.map((route) => ({
    ...route,
    element: <RequiresAuth>{route.element}</RequiresAuth>,
  }));
};

const Routes = () => {
  // ** Un-Auth
  const routesForNotAuthenticatedOnly: RouteObject[] = applySuspense([
    {
      element: <RequiresUnAuth />,
      children: AuthenticationRoutes,
    },
  ]);

  const routesForAuthenticatedOnly: RouteObjType[] = applyRequiresAuth([
    {
      path: PRIVATE_NAVIGATION.DASHBOARD.VIEW,
      element: <Dashboard />,
    },
    {
      path: PRIVATE_NAVIGATION.LEAD.VIEW,
      element: <Lead />,
    },
    {
      path: PRIVATE_NAVIGATION.LEAD.ADD,
      element: <AddEditLead />,
    },
    {
      path: PRIVATE_NAVIGATION.LEAD.EDIT,
      element: <AddEditLead />,
    },
    {
      path: PRIVATE_NAVIGATION.CUSTOMER.VIEW,
      element: <Customer />,
    },
    {
      path: PRIVATE_NAVIGATION.CUSTOMER.ADD,
      element: <AddEditCustomer />,
    },
    {
      path: PRIVATE_NAVIGATION.CUSTOMER.EDIT,
      element: <AddEditCustomer />,
    },
    {
      path: PRIVATE_NAVIGATION.ORDER.VIEW,
      element: <Order />,
    },
    {
      path: PRIVATE_NAVIGATION.INSTALLER_HISTORY.VIEW,
      element: <InstallerHistory />,
    },
    {
      path: PRIVATE_NAVIGATION.ORDER.ADD,
      element: <AddEditOrder />,
    },
    {
      path: PRIVATE_NAVIGATION.ORDER.EDIT,
      element: <AddEditOrder />,
    },
    {
      path: PRIVATE_NAVIGATION.PURCHASE_ORDER.VIEW,
      element: <PurchaseOrder />,
    },
    {
      path: PRIVATE_NAVIGATION.PURCHASE_ORDER.ADD,
      element: <AddEditPurchaseOrder />,
    },
    {
      path: PRIVATE_NAVIGATION.PURCHASE_ORDER.EDIT,
      element: <AddEditPurchaseOrder />,
    },
    {
      path: PRIVATE_NAVIGATION.PRODUCT.VIEW,
      element: <Product />,
    },
    {
      path: PRIVATE_NAVIGATION.PRODUCT.ADD,
      element: <AddEditProduct />,
    },
    {
      path: PRIVATE_NAVIGATION.PRODUCT.EDIT,
      element: <AddEditProduct />,
    },
    {
      path: PRIVATE_NAVIGATION.SALES_COMMISSION.VIEW,
      element: <SalesCommission />,
    },
    {
      path: PRIVATE_NAVIGATION.INVOICE.VIEW,
      element: <Invoice />,
    },
    {
      path: PRIVATE_NAVIGATION.INVOICE.ADD,
      element: <AddEditInvoice />,
    },
    {
      path: PRIVATE_NAVIGATION.INVOICE.EDIT,
      element: <AddEditInvoice />,
    },
    {
      path: PRIVATE_NAVIGATION.TEAM.VIEW,
      element: <Team />,
    },
    {
      path: PRIVATE_NAVIGATION.TEAM.ADD,
      element: <AddEditTeam />,
    },
    {
      path: PRIVATE_NAVIGATION.TEAM.EDIT,
      element: <AddEditTeam />,
    },
    {
      path: PRIVATE_NAVIGATION.REPORT.VIEW,
      element: <Report />,
    },
    {
      element: <SettingLayout />,
      children: AllSettingRoutes(),
      errorElement: <ErrorBoundary />,
    },

    {
      path: PRIVATE_NAVIGATION.CALENDAR.VIEW,
      element: <Calendar />,
    },
  ]);

  const routesForPublic: RouteObject[] = [
    {
      path: PUBLIC_NAVIGATION.NOT_AUTHORIZED,
      element: <NotAuthorized />,
    },
    {
      path: PUBLIC_NAVIGATION.STATIC,
      element: <StaticDashboard />,
    },
  ];

  const notFound: RouteObject[] = [
    {
      path: "*",
      element: (
        <RequiresAuth>
          <NotFound />
        </RequiresAuth>
      ),
    },
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...routesForNotAuthenticatedOnly,
    ...routesForAuthenticatedOnly,
    ...notFound,
  ]);
  return <RouterProvider router={router} />;
};

export default Routes;
