// ** React Imports **
import { SVGAttributes } from "react";

// ** CSS **
// import "./style/customIcon.css";

import { ReactComponent as NoDataFound } from "assets/icons/no-data-found.svg";
import { ReactComponent as AddFile } from "assets/icons/stroke-icons/addFile.svg";
import { ReactComponent as AddFolder } from "assets/icons/stroke-icons/addFolder.svg";
import { ReactComponent as AddUser } from "assets/icons/stroke-icons/addUser.svg";
import { ReactComponent as ArrowDownBold } from "assets/icons/stroke-icons/arrowDownBold.svg";
import { ReactComponent as ArrowLeftBold } from "assets/icons/stroke-icons/arrowLeftBold.svg";
import { ReactComponent as ArrowRightBold } from "assets/icons/stroke-icons/arrowRightBold.svg";
import { ReactComponent as ArrowUpBold } from "assets/icons/stroke-icons/arrowUpBold.svg";
import { ReactComponent as Bell } from "assets/icons/stroke-icons/bell.svg";
import { ReactComponent as Calendar } from "assets/icons/stroke-icons/calendar.svg";
import { ReactComponent as CheckArrow } from "assets/icons/stroke-icons/checkArrow.svg";
import { ReactComponent as CheckArrowBold } from "assets/icons/stroke-icons/checkArrowBold.svg";
import { ReactComponent as Close } from "assets/icons/stroke-icons/close.svg";
import { ReactComponent as CloseBold } from "assets/icons/stroke-icons/closeBold.svg";
import { ReactComponent as DotsThreeVertical } from "assets/icons/stroke-icons/dotsThreeVertical.svg";
import { ReactComponent as EnvelopeSimple } from "assets/icons/stroke-icons/envelopeSimple.svg";
import { ReactComponent as Estimate } from "assets/icons/stroke-icons/estimate.svg";
import { ReactComponent as Expenses } from "assets/icons/stroke-icons/expenses.svg";
import { ReactComponent as File } from "assets/icons/stroke-icons/file.svg";
import { ReactComponent as Graph } from "assets/icons/stroke-icons/graph.svg";
import { ReactComponent as HandShake } from "assets/icons/stroke-icons/handShake.svg";
import { ReactComponent as Home } from "assets/icons/stroke-icons/home.svg";
import { ReactComponent as Invoice } from "assets/icons/stroke-icons/invoice.svg";
import { ReactComponent as Lead } from "assets/icons/stroke-icons/lead.svg";
import { ReactComponent as Lock } from "assets/icons/stroke-icons/lock.svg";
import { ReactComponent as Logout } from "assets/icons/stroke-icons/logout.svg";
import { ReactComponent as Order } from "assets/icons/stroke-icons/order.svg";
import { ReactComponent as PlusCircle } from "assets/icons/stroke-icons/plusCircle.svg";
import { ReactComponent as Product } from "assets/icons/stroke-icons/product.svg";
import { ReactComponent as Search } from "assets/icons/stroke-icons/search.svg";
import { ReactComponent as Setting } from "assets/icons/stroke-icons/setting.svg";
import { ReactComponent as User } from "assets/icons/stroke-icons/user.svg";
import { ReactComponent as Users } from "assets/icons/stroke-icons/users.svg";
import { ReactComponent as Watch } from "assets/icons/stroke-icons/watch.svg";
import { ReactComponent as GmailSquareBox } from "assets/icons/stroke-icons/gmailSquareBox.svg";
import { ReactComponent as NoteBig } from "assets/icons/stroke-icons/noteBig.svg";
import { ReactComponent as M2 } from "assets/icons/stroke-icons/m2.svg";
import { ReactComponent as FilterFunnel } from "assets/icons/stroke-icons/filterFunnel.svg";
import { ReactComponent as Plus } from "assets/icons/stroke-icons/plus.svg";
import { ReactComponent as Report1 } from "assets/icons/stroke-icons/report1.svg";
import { ReactComponent as Report2 } from "assets/icons/stroke-icons/report2.svg";
import { ReactComponent as Report3 } from "assets/icons/stroke-icons/report3.svg";
import { ReactComponent as Report4 } from "assets/icons/stroke-icons/report4.svg";
import { ReactComponent as Report5 } from "assets/icons/stroke-icons/report5.svg";
import { ReactComponent as CaretLeft } from "assets/icons/stroke-icons/caretLeft.svg";
import { ReactComponent as CaretLeftBold } from "assets/icons/stroke-icons/caretLeftBold.svg";
import { ReactComponent as CaretRight } from "assets/icons/stroke-icons/caretRight.svg";
import { ReactComponent as CaretRightBold } from "assets/icons/stroke-icons/caretRightBold.svg";
import { ReactComponent as ImageSquare } from "assets/icons/stroke-icons/imageSquare.svg";
import { ReactComponent as UploadSimple } from "assets/icons/stroke-icons/uploadSimple.svg";
import { ReactComponent as Trash } from "assets/icons/stroke-icons/trash.svg";
import { ReactComponent as Copy } from "assets/icons/stroke-icons/copy.svg";
import { ReactComponent as Pricing } from "assets/icons/stroke-icons/pricing.svg";
import { ReactComponent as PencilSimple } from "assets/icons/stroke-icons/pencilSimple.svg";
import { ReactComponent as Eye } from "assets/icons/stroke-icons/eye.svg";
import { ReactComponent as SealDiscount } from "assets/icons/stroke-icons/sealDiscount.svg";
import { ReactComponent as Warning } from "assets/icons/stroke-icons/warning.svg";
import { ReactComponent as DotsNineFilled } from "assets/icons/stroke-icons/dotsNine.svg";
import { ReactComponent as CaretDown } from "assets/icons/stroke-icons/caretDown.svg";
import { ReactComponent as CaretDownBold } from "assets/icons/stroke-icons/caretDownBold.svg";
import { ReactComponent as ViewPro } from "assets/icons/stroke-icons/viewPro.svg";
import { ReactComponent as DeletePro } from "assets/icons/stroke-icons/deletePro.svg";
import { ReactComponent as EditPro } from "assets/icons/stroke-icons/editPro.svg";
import { ReactComponent as InStock } from "assets/icons/stroke-icons/inStock.svg";
import { ReactComponent as Link } from "assets/icons/stroke-icons/link.svg";
import { ReactComponent as Money } from "assets/icons/stroke-icons/money.svg";
import { ReactComponent as Export } from "assets/icons/stroke-icons/export.svg";
import { ReactComponent as M2Icon } from "assets/icons/stroke-icons/m2Icon.svg";
import { ReactComponent as HorizontalNFilled } from "assets/icons/filled-icons/horizontalN.svg";
import { ReactComponent as PaperPlaneRightFilled } from "assets/icons/filled-icons/paperPlaneRight.svg";

// ** Component **
import Image from "components/Image";

// ** Types **
import { IconTypes } from "./types";

type IconProps = {
  name: IconTypes;
  iconType?: "default" | "custom";
  className?: string;
  svgWrapperClass?: string;
  onClick?: (..._args: any[]) => void;
  iIconStyle?: { backgroundColor: string };
} & SVGAttributes<SVGElement>;

const Icon = ({
  name,
  iconType = "default",
  className = "",
  svgWrapperClass = "",
  iIconStyle,
  onClick,
  style,
  fill,
  ...restProps
}: IconProps) => {
  const rest = { ...restProps, style: { ...(style || {}), fill } };

  const renderIcon = (icon: IconTypes) => {
    switch (icon) {
      case "addFile":
        return <AddFile {...rest} />;
      case "addFolder":
        return <AddFolder {...rest} />;
      case "addUser":
        return <AddUser {...rest} />;
      case "calendar":
        return <Calendar {...rest} />;
      case "estimate":
        return <Estimate {...rest} />;
      case "expenses":
        return <Expenses {...rest} />;
      case "file":
        return <File {...rest} />;
      case "graph":
        return <Graph {...rest} />;
      case "handShake":
        return <HandShake {...rest} />;
      case "home":
        return <Home {...rest} />;
      case "invoice":
        return <Invoice {...rest} />;
      case "lead":
        return <Lead {...rest} />;
      case "lock":
        return <Lock {...rest} />;
      case "order":
        return <Order {...rest} />;
      case "product":
        return <Product {...rest} />;
      case "setting":
        return <Setting {...rest} />;
      case "user":
        return <User {...rest} />;
      case "users":
        return <Users {...rest} />;
      case "arrowDownBold":
        return <ArrowDownBold {...rest} />;
      case "arrowUpBold":
        return <ArrowUpBold {...rest} />;
      case "arrowLeftBold":
        return <ArrowLeftBold {...rest} />;
      case "arrowRightBold":
        return <ArrowRightBold {...rest} />;
      case "bell":
        return <Bell {...rest} />;
      case "checkArrow":
        return <CheckArrow {...rest} />;
      case "checkArrowBold":
        return <CheckArrowBold {...rest} />;
      case "close":
        return <Close {...rest} />;
      case "closeBold":
        return <CloseBold {...rest} />;
      case "dotsThreeVertical":
        return <DotsThreeVertical {...rest} />;
      case "plusCircle":
        return <PlusCircle {...rest} />;
      case "search":
        return <Search {...rest} />;
      case "envelopeSimple":
        return <EnvelopeSimple {...rest} />;
      case "logout":
        return <Logout {...rest} />;
      case "noDataFound":
        return <NoDataFound {...rest} />;
      case "watch":
        return <Watch {...rest} />;
      case "gmailSquareBox":
        return <GmailSquareBox {...rest} />;
      case "noteBig":
        return <NoteBig {...rest} />;
      case "m2":
        return <M2 {...rest} />;
      case "horizontalNFilled":
        return <HorizontalNFilled {...rest} />;
      case "filterFunnel":
        return <FilterFunnel {...rest} />;
      case "plus":
        return <Plus {...rest} />;
      case "report1":
        return <Report1 {...rest} />;
      case "report2":
        return <Report2 {...rest} />;
      case "report3":
        return <Report3 {...rest} />;
      case "report4":
        return <Report4 {...rest} />;
      case "report5":
        return <Report5 {...rest} />;
      case "caretLeft":
        return <CaretLeft {...rest} />;
      case "caretRight":
        return <CaretRight {...rest} />;
      case "imageSquare":
        return <ImageSquare {...rest} />;
      case "uploadSimple":
        return <UploadSimple {...rest} />;
      case "trash":
        return <Trash {...rest} />;
      case "copy":
        return <Copy {...rest} />;
      case "caretLeftBold":
        return <CaretLeftBold {...rest} />;
      case "caretRightBold":
        return <CaretRightBold {...rest} />;
      case "pricing":
        return <Pricing {...rest} />;
      case "pencilSimple":
        return <PencilSimple {...rest} />;
      case "sealDiscount":
        return <SealDiscount {...rest} />;
      case "warning":
        return <Warning {...rest} />;
      case "dotsNineFilled":
        return <DotsNineFilled {...rest} />;
      case "caretDown":
        return <CaretDown {...rest} />;
      case "caretDownBold":
        return <CaretDownBold {...rest} />;
      case "eye":
        return <Eye {...rest} />;
      case "viewPro":
        return <ViewPro {...rest} />;
      case "deletePro":
        return <DeletePro {...rest} />;
      case "editPro":
        return <EditPro {...rest} />;
      case "link":
        return <Link {...rest} />;
      case "money":
        return <Money {...rest} />;
      case "export":
        return <Export {...rest} />;
      case "m2Icon":
        return <M2Icon {...rest} />;
      case "inStock":
        return <InStock {...rest} />;
      case "paperPlaneRightFilled":
        return <PaperPlaneRightFilled {...rest} />;
      default:
        return <></>;
    }
  };
  const renderIconContent = () => {
    if (iconType === "default") {
      return (
        <div className={`svgWrapper relative z-[2] ${svgWrapperClass}`}>
          {renderIcon(name)}
        </div>
      );
    }
    if (iconType === "custom") {
      return (
        <Image
          imgPath={name}
          serverPath
          imgClassName="w-[32px] h-[32px] i__Icon rounded-[12px]"
        />
      );
    }
    return <></>;
  };

  return (
    <div
      style={iIconStyle}
      className={`iconWrapper relative before:content-[''] before:duration-300 before:absolute before:top-0 before:left-0 before:w-full before:h-full ${className}`}
      onClick={onClick}
    >
      {renderIconContent()}
    </div>
  );
};

export default Icon;
