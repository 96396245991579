// ** Imported Packages **
import React from "react";
import { Navigate } from "react-router-dom";

// ** Components **
import RequiresAuth from "modules/Auth/components/RequiresAuth";

// ** Constants **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";

// ** Type **
import { RouteObjType } from "types";

const applySuspense = (routes: RouteObjType[]): RouteObjType[] => {
  return routes.map((route) => {
    return {
      ...route,
      element: <RequiresAuth isPageLoader>{route.element}</RequiresAuth>,
    };
  });
};

// ** General Settings Routes

const FactorType = React.lazy(
  () => import("./sub-modules/general-settings/FactorType")
);
const JobStatus = React.lazy(
  () => import("./sub-modules/general-settings/JobStatus")
);
const MatType = React.lazy(
  () => import("./sub-modules/general-settings/MatType")
);
const ProductType = React.lazy(
  () => import("./sub-modules/general-settings/ProductType")
);
const ReferenceType = React.lazy(
  () => import("./sub-modules/general-settings/ReferenceType")
);
const RoomType = React.lazy(
  () => import("./sub-modules/general-settings/RoomType")
);
const OrderItemStatus = React.lazy(
  () => import("./sub-modules/general-settings/OrderItemStatus")
);
const OrderJobStatus = React.lazy(
  () => import("./sub-modules/general-settings/OrderJobStatus")
);
const OrderJobType = React.lazy(
  () => import("./sub-modules/general-settings/OrderJobType")
);
const ShippingOption = React.lazy(
  () => import("./sub-modules/general-settings/ShippingOption")
);
const PaymentCode = React.lazy(
  () => import("./sub-modules/general-settings/PaymentCode")
);
const PurchaseOrderItemStatus = React.lazy(
  () => import("./sub-modules/general-settings/PurchaseOrderItemStatus")
);

const ProductCode = React.lazy(
  () => import("modules/Setting/sub-modules/product/code")
);

const Vendor = React.lazy(() => import("modules/Setting/sub-modules/vendor"));

const Installer = React.lazy(
  () => import("modules/Setting/sub-modules/Installer")
);

const Pricing = React.lazy(() => import("modules/Setting/sub-modules/pricing"));

const RolesAndPermission = React.lazy(
  () => import("modules/Setting/sub-modules/rolesAndPermission")
);
const AccountType = React.lazy(
  () => import("modules/Setting/sub-modules/general-settings/AccountType")
);

const GlAccount = React.lazy(
  () => import("modules/Setting/sub-modules/GlAccount")
);

const settingRoutes = () => {
  const settingRoutes = applySuspense([
    {
      path: PRIVATE_NAVIGATION.SETTING.VIEW,
      element: (
        <Navigate
          to={PRIVATE_NAVIGATION.SETTING.GENERAL_SETTING.REF_SRC_TYPE}
        />
      ),
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.GENERAL_SETTING.REF_SRC_TYPE,
      element: <ReferenceType />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.GENERAL_SETTING.FACTOR,
      element: <FactorType />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.LEAD_SETTING.VIEW,
      element: (
        <Navigate to={PRIVATE_NAVIGATION.SETTING.LEAD_SETTING.JOB_STATUS} />
      ),
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.LEAD_SETTING.JOB_STATUS,
      element: <JobStatus />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.LEAD_SETTING.MAT_TYPE,
      element: <MatType />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.LEAD_SETTING.ROOM_TYPE,
      element: <RoomType />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.ORDER_SETTING.VIEW,
      element: (
        <Navigate to={PRIVATE_NAVIGATION.SETTING.ORDER_SETTING.JOB_STATUS} />
      ),
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.ORDER_SETTING.JOB_STATUS,
      element: <OrderJobStatus />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.ORDER_SETTING.JOB_TYPE,
      element: <OrderJobType />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.ORDER_SETTING.ITEM_STATUS,
      element: <OrderItemStatus />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.ORDER_SETTING.PAYMENT_CODE,
      element: <PaymentCode />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.PRODUCT.VIEW,
      element: <Navigate to={PRIVATE_NAVIGATION.SETTING.PRODUCT.CODE} />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.PRODUCT.CODE,
      element: <ProductCode />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.PRODUCT.TYPE,
      element: <ProductType />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.ACCOUNT_SETTING.VIEW,
      element: (
        <Navigate
          to={PRIVATE_NAVIGATION.SETTING.ACCOUNT_SETTING.ACCOUNT_TYPE}
        />
      ),
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.ACCOUNT_SETTING.ACCOUNT_TYPE,
      element: <AccountType />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.ACCOUNT_SETTING.GL_ACCOUNT,
      element: <GlAccount />,
    },

    {
      path: PRIVATE_NAVIGATION.SETTING.PURCHASE_ORDER_SETTING.VIEW,
      element: (
        <Navigate
          to={PRIVATE_NAVIGATION.SETTING.PURCHASE_ORDER_SETTING.ITEM_STATUS}
        />
      ),
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.PURCHASE_ORDER_SETTING.ITEM_STATUS,
      element: <PurchaseOrderItemStatus />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.PURCHASE_ORDER_SETTING.SHIPPING_OPTION,
      element: <ShippingOption />,
    },

    {
      path: PRIVATE_NAVIGATION.SETTING.VENDOR.VIEW,
      element: <Vendor />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.INSTALLER.VIEW,
      element: <Installer />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.PRICING.VIEW,
      element: <Pricing />,
    },
    {
      path: PRIVATE_NAVIGATION.SETTING.ROLES_PERMISSION.VIEW,
      element: <RolesAndPermission />,
    },
  ]);

  return settingRoutes;
};

export default settingRoutes;
