// ** Packages **
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MODULE } from "constants/entity.constant";

// ** Redux **
import { RootStateType } from "redux/store";

// ** Type **
import { DynamicObjectType } from "types";

const initialState: DynamicObjectType = {
  [MODULE.CUSTOMER]: [{}],
  [MODULE.ESTIMATE]: [{}],
  [MODULE.LEAD]: [{}],
  [MODULE.ORDER]: [{}],
  [MODULE.PURCHASE_ORDER]: [{}],
  [MODULE.PURCHASE_ORDER_ITEMS]: [{}],
  [MODULE.ORDER_ITEM]: [{}],
  [MODULE.PRODUCT]: [{}],
  [MODULE.TEAM]: [{}],
  [MODULE.INVOICE]: [{}],
  [MODULE.INSTALLER]: [{}],
  [MODULE.INSTALLER_HISTORY]: [{}],
  [MODULE.SALES_COMMISSION]: [{}],
  [MODULE.SALES_REPORT]: [{}],
  [MODULE.GL_ACCOUNT]: [{}],
};

const slice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setModuleWiseFilter(
      state: DynamicObjectType,
      action: PayloadAction<{ moduleName: MODULE; filter: DynamicObjectType[] }>
    ) {
      const { filter, moduleName } = action.payload;
      state[moduleName] = filter?.length ? filter : [{}];
    },
  },
});

export const { reducer } = slice;

export const { setModuleWiseFilter } = slice.actions;

export const getModuleWiseFilter = (state: RootStateType) => state.filter;

export default slice;
