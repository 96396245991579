export enum MODULE {
  LEAD = "lead",
  CUSTOMER = "customer",
  PRODUCT = "product",
  ORDER = "order",
  PURCHASE_ORDER = "purchase_order",
  PURCHASE_ORDER_ITEMS = "purchase_order_items",
  ORDER_ITEM = "order_items",
  ESTIMATE = "estimate",
  TEAM = "team",
  INSTALLER = "installer",
  INSTALLER_HISTORY = "installer_history",
  SALES_COMMISSION = "sales_commission",
  SALES_REPORT = "sales_report",
  LEAD_REPORT = "lead_report",
  GL_ACCOUNT = "gl_account",
  INVOICE = "invoice",
}

export enum EXPORT_MODULE {
  LEAD = "lead",
  CUSTOMER = "customer",
  PRODUCT = "product",
  ORDER = "order",
  PURCHASE_ORDER = "purchase_order",
  SALES_COMMISSION = "sales_commission",
  INSTALLER_HISTORY = "installer_history",
  TEAM = "team",
  INSTALLER = "installer",
  GL_ACCOUNT = "gl_account",
}

export const TIMELINE_MODEL_NAME = {
  PRODUCT_VARIATION: "product_variation",
  ORDER: "order",
  PURCHASE_ORDER: "purchase_order",
  CUSTOMER: "customer",
};

// export enum PERMISSION_MODULE {
//   LEAD = "leads",
//   CUSTOMER = "customers",
//   PRODUCT = "products",
//   ORDER = "orders",
//   PURCHASE_ORDER = "purchase_orders",
//   SALES_COMMISSION = "sales_commissions",
// }

export enum PERMISSION_MODULE {
  LEAD = "leads",
  CUSTOMER = "customers",
  PRODUCT = "products",
  ORDER = "orders",
  PURCHASE_ORDER = "purchase_orders",
  TEAM = "teams",
  SETTING = "settings",
  SALES_COMMISSION = "sales_commission",
  CALENDAR = "calendar",
  INSTALLER_HISTORY = "installer_history",
  INVOICE = "invoice",
}

export enum ACTION {
  READ = "read",
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
  DOCUMENT = "document",
  NOTE = "note",
  TAG = "tag",
}
